import axios from "axios";
import router from "@/router";
import paths from "@/router/paths";
import qs from "qs";

export default class NetworkService {
  baseUrl = process.env.VUE_APP_API_BASE_URL;
  endpoints = {
    login: "api/login",
    users: "api/users",
    posts: "api/posts",
    terms: "api/terms-of-use",
    termsPsi: "api/terms-of-use-psi",
    privacyPolicy: "api/privacy-policy",
    appointments: "api/appointments",
    summary: "api/admin-summary",
    passwordReset: "api/password/reset",
    reports: "api/appointment-reports",
    postCategories: "api/post-categories",
    appointmentGraphics: "api/graphicsAppointmentsDays",
    appCrashes: "api/app-crashes",
    refund: "api/refund-admin",
    exportUsers: "api/reports/patients",
    coupons: "api/coupons",
    givePremium: "api/admin/give-premium",
    removePremium: "api/admin/remove-premium",
    ratingsReport: "api/calls-rating",
    reviewRating: "api/rating-review",
    homeVideos: "api/home-videos",
    partnerships: "api/partnerships",
    partnerPatients: "api/partner-patients",
    freeAppointment: "api/appointments/free-session",
    appointmentRecurrencies: "api/appointment-recurrencies",

  };
  constructor() {}

  post(endPoint, params) {
    return axios
      .post(this.baseUrl + endPoint, params, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }
  get(endPoint, query = {}, defaultToken = "") {
    if (query.search) {
      query.q = query.search.trim();
    }
    if (query.orderBy && query.orderBy.length) {
      // query.orderBy = `${query.orderBy[0].sortName}|${query.orderBy[0].order}`
      query.orderByDirection = query.orderBy[0].order; // asc ou desc
      query.orderBy = query.orderBy[0].sortName; // nome da coluna
    }
    delete query.search;
    let url = `${this.baseUrl}${endPoint}?${qs.stringify(query)}`;

    return axios
      .get(url, { headers: this.headers("", defaultToken) })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }
  put(endPoint, params) {
    return axios
      .put(this.baseUrl + endPoint, params, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }
  delete(endPoint) {
    return axios
      .delete(this.baseUrl + endPoint, { headers: this.headers() })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }

  postEncoded(endPoint, params) {
    return axios
      .post(this.baseUrl + endPoint, qs.stringify(params), {
        headers: this.headers("urlencoded"),
      })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }

  putEncoded(endPoint, params) {
    var token = localStorage.getItem("token");
    console.log(token);
    if (token == null) {
      token = "";
    }
    return axios
      .put(this.baseUrl + endPoint, qs.stringify(params), {
        headers: this.headers("urlencoded"),
      })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }

  postMultipart(endPoint, params) {
    return axios
      .post(this.baseUrl + endPoint, this.makeItMultipartParams(params), {
        headers: this.headers("multipart"),
      })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }

  putMultipart(endPoint, params) {
    var token = localStorage.getItem("token");
    console.log(token);
    if (token == null) {
      token = "";
    }
    return axios
      .put(this.baseUrl + endPoint, this.makeItMultipartParams(params), {
        headers: this.headers("multipart"),
      })
      .then((res) => this.handleResponse(res))
      .catch((e) => {
        this.handleError(e);
      });
  }
  makeItMultipartParams(params) {
    var p = new FormData();
    Object.keys(params).forEach(function (key, index) {
      if (Array.isArray(params[key])) {
        params[key].map((r) => {
          p.append(`${key}[]`, r);
        });
      } else {
        console.log(key);
        console.log(params[key]);
        p.append(key, params[key]);
      }
    });
    return p;
  }

  makeExternalRequest(type, endPoint, params, headers = {}) {
    switch (type) {
      case "post":
        return axios.post(endPoint, params, { headers });

      case "get":
        return axios.get(endPoint, { headers });

      case "put":
        return axios.put(endPoint, params, { headers });
    }
  }
  headers(encodeType = "", defaultToken = "") {
    var access_token =
      localStorage.getItem(process.env.VUE_APP_API_TOKEN_KEY) || defaultToken;
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (encodeType == "multipart") {
      delete headers["Content-Type"];
    }
    if (encodeType == "urlencoded") {
      headers["Content-Type"] = `application/x-www-form-urlencoded`;
    }
    if (access_token) {
      headers["Authorization"] = `Bearer ${access_token}`;
    }
    return headers;
  }

  handleResponse(res) {
    if (res.data.status === false) {
      this.handleError(res);
    } else {
      return res.data;
    }
  }
  handleError(error) {
    const e = error.response || error;
    console.log("ERRRRRROOOOOOOOOOOOOOOO", e);
    if (e && e.status && e.status == 401) {
      router.push(paths.login);
    }
    let message =
      e?.data?.message || e?.message || "Ocorreu um erro, tente novamente";
    if (e?.data?.errors) {
      const keys = Object.keys(e.data?.errors);
      message = e.data?.errors[keys[0]][0];
      console.log("meeeeeeeessage", message);
    }
    throw { message };
  }
}
