<template>
  <div id="appointment_profile">
    <div class="sticky-top">
      <div class="card">

        <!-- CARD HEADER -->
        <div class="card-img-top bg-light">
          <div class="p-4">
            <div class="row">
              <div class="col-12">
                <router-link class="card mb-0 lift" :to="`${$paths.psychologists.detail}/${item.psychologist?.id}`"
                  target="_blank">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <div class="avatar avatar-lg">
                          <img :src="`${$baseImageUrl}${item.psychologist?.profile_image}`" alt="..."
                            class="avatar-img rounded">
                        </div>
                      </div>
                      <div class="col ms-n2">
                        <p class="small fw-bold text-muted mb-1">
                          <i class="fa-solid fa-user-doctor me-1"></i>
                          Psicólogo
                        </p>
                        <h4 class="fw-bold text-dark mb-1">
                          {{ item.psychologist?.name || '' }}
                          <!-- <i class="fa-solid fa-square-arrow-up-right ms-1 text-muted"></i> -->
                        </h4>
                        <span v-if="item.psychologist?.deleted_at" class="small text-danger">Conta excluída</span>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="card-body text-center m-0 p-0">
                <div class="avatar avatar-sm card-avatar" style="margin-top: -0.75rem; z-index:1;">
                  <img src="@/assets/img/icon_relationship.svg" class="avatar-img rounded-circle  shadow" alt="...">
                </div>
              </div>
              <div class="col-12" style="margin-top: -1.5rem;">
                <router-link class="card mb-0 lift" :to="`${$paths.users.detail}/${item.user_id}`" target="_blank">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <div class="avatar avatar-lg">
                          <img src="@/assets/img/avatar_placeholder.svg" alt="..." class="avatar-img rounded">
                        </div>
                      </div>
                      <div class="col ms-n2">
                        <p class="small fw-bold text-muted mb-1">
                          <i class="fa-solid fa-user me-1"></i>
                          Paciente
                        </p>
                        <h4 class="fw-bold text-dark mb-1">
                          {{ `${item.user?.name}` }}
                          <!-- <i class="fa-solid fa-square-arrow-up-right ms-1 text-muted"></i> -->
                        </h4>
                        <span v-if="item.user?.deleted_at" class="small text-danger">Conta excluída</span>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>

        </div>

        <!-- CARD BODY -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="list-group list-group-flush my-n3 pb-3">
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Tipo
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="fw-bold mb-0">
                        {{ item.formatted_type }}
                        <span v-if="item.online">(online)</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Provedor da chamada
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="fw-bold mb-0">
                        {{ item.callProviderLabel }}
                        <!-- <span v-if="item.online">(online)</span> -->
                      </p>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Data criada
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="fw-bold mb-0">
                        {{ $moment(item.created_at).format("DD/MM/YYYY - HH:mm") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Data da sessão
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="fw-bold mb-0">
                        {{ item.formatted_date }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="list-group-item"  v-if="item?.partnership_id">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Parceria 
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="fw-bold mb-0">
                        <span class="text-success fw-bold mb-0">
                         <a :href="`${$paths.partners.detail}/${item?.partnership_id}`" target="_blank">

                          {{ item.partnership?.company_name }} <i class="fa-solid fa-arrow-up-right-from-square"></i>

                         </a>


                        </span>
                     

                      </p>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Recorrência
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="fw-bold mb-0">
                        <span class="fw-bold mb-0" v-if="item.recurrency">
                          <span class="fw-bold">
                            
                            <span v-if="item.recurrency.frequency == 'weekly' && item.recurrency.interval == 1">  Semanal </span>
                            <span v-else-if="item.recurrency.frequency == 'weekly' && item.recurrency.interval == 2"> Quinzenal </span>
                            <span v-else-if="item.recurrency.frequency == 'monthly' && item.recurrency.interval == 1"> Mensal </span>
                            <span v-else> {{ item.recurrency.frequency }} Interval {{item.recurrency.interval}} </span>
                          </span>
                          <i class="fa-solid fa-circle fa-xs text-success me-2" v-if="item.recurrency.status == 'active'"></i>
                          <i class="fa-solid fa-circle-xmark text-danger me-2" v-else></i>
                          <button v-if="item.recurrency.status == 'active'" class="btn btn-danger btn-sm ms-2" @click="handleCancelRecurrency">
                            Cancelar 
                          </button>
                        </span>
                        <span class="mb-0" v-else>
                          <span> Avulsa </span>
                          <i class="fa-solid fa-circle fa-xs text-muted me-2"></i>

                        </span>

                        

                      </p>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Status
                      </p>
                    </div>
                    <div class="col-auto">
                      <p v-if="item.statusObject?.title === 'Cancelada'" class="fw-bold mb-0 text-danger">
                        {{ item.statusObject?.title }}
                        <i class="fa-solid fa-circle-xmark ms-1"></i>
                      </p>
                      <p v-if="item.statusObject?.title === 'Recusado'" class="fw-bold mb-0 text-danger">
                        {{ item.statusObject?.title }}
                        <i class="fa-solid fa-hand ms-1"></i>
                      </p>
                      <p v-if="item.statusObject?.title === 'Concluída'" class="fw-bold mb-0 text-success">
                        {{ item.statusObject?.title }}
                        <i class="fa-solid fa-circle-check ms-1"></i>
                      </p>
                      <p v-if="item.statusObject?.title === 'Aguardando'" class="fw-bold mb-0 text-warning">
                        {{ item.statusObject?.title }}
                        <i class="fa-solid fa-clock ms-1"></i>
                      </p>
                      <p v-if="item.statusObject?.title === 'Agendado'" class="fw-bold mb-0 text-info">
                        {{ item.statusObject?.title }}
                        <i class="fa-regular fa-calendar-check ms-1"></i>
                      </p>
                    </div>




                    <!--  -->






                    <div class="col-12">
                      <div v-if="item.reason_for_cancellation" class="card bg-light border-0 m-0 mt-3">
                        <div class="m-3">
                          <p class="mb-2 fw-bold">
                            <i class="fa-solid fa-circle-info me-1"></i>
                            Motivo do cancelamento
                          </p>
                          <p class="small text-body-secondary mb-0">
                            {{ item.reason_for_cancellation }}.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Valor
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="fw-bold mb-0">
                        <span v-if="item.coupon" class="text-muted text-decoration-line-through pe-2 text-gray-500">
                          {{ item.original_value | toCurrency }}
                        </span>
                        <span>
                          {{ item.value | toCurrency }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" v-if="item.coupon">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Código Aplicado
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="mb-0 fw-bold">
                        <span>
                          {{ item.coupon.code }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="list-group-item border-bottom-0">
                  <div class="row align-items-center">
                    <div class="col text-start">
                      <p class="text-muted mb-0">
                        Pagamento
                      </p>
                    </div>
                    <div class="col-auto">
                      <p :class="statusClass" class="fw-bold mb-0">
                        {{ item.paymentStatus?.title }}
                        <i :class="statusIcon" class="ms-1 fa-solid"></i>
                        <a :href="item.stripe_link" target="_blank" v-if="item.pg_transaction_id">
                          <i class="fa-brands fa-cc-stripe fa-xl text-dark" style="margin-left: 5px"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div v-if="item.paymentStatus?.description" class="card bg-light border-0 m-0">
                    <div class="m-3">
                      <p class="mb-2 fw-bold">
                        <i class="fa-solid fa-circle-info me-1"></i>
                        Status de pagamento
                      </p>
                      <p class="small text-body-secondary mb-0">
                        {{ item.paymentStatus?.description }}.
                      </p>
                    </div>
                  </div>
                </div>
                
                <div v-if="item.refundAllowed" class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col d-grid">
                      <button @click="refundModal.toggle()" class="btn btn-outline-secondary block">
                        <i class="fa-solid fa-hand-holding-dollar me-1"></i>
                        Reembolsar paciente
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- REFUND MODAL -->
    <ModalComponent :title="'Efetuar reembolso'" id="refundModal">
      <template v-slot:body>
        Você efetuará o reemboldo de <strong>{{ item.value | toCurrency }}</strong>. Essa ação não pode ser desfeita. Para
        continuar,
        confirme sua senha.
        <div class="mt-3">
          <PasswordInput label="" :model="$v.password" class="text-white" placeholder="Confirme a senha" />
          <p class="text-center text-danger">
            {{ errorMsg }}
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <FormButton label="Efetuar reembolso" :loading="loading" :disabled="$v.$invalid"
            class="btn w-15 btn-primary mb-3" @onClick="handleRefund" />
        </div>
        <!-- <button type="button" :disabled="loading" @click="handleRefund" class="btn btn-primary w-15"> -->

        <!-- </button> -->
      </template>
    </ModalComponent>

  </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'ProfileComponent',
  mounted() {
    this.refundModal = new Modal(document.getElementById('refundModal'), {
      backdrop: 'static',
      keyboard: false
    })
  },
  data() {
    return {
      errorMsg: '',
      refundModal: null,
      password: '',
      loading: false
    }
  },
  validations: {
    password: { required }
  },
  props: {
    item: Object,
    default: {}
  },
  computed: {
    statusClass() {
      const status = this.item.paymentStatus?.title;
      return {
        'text-success': status === 'Pago',
        'text-danger': status === 'Cancelado' || status === 'Reembolsado',
        'text-muted': status === 'Sem status',
      };
    },
    statusIcon() {
      const status = this.item.paymentStatus?.title;
      return {
        'fa-circle-check': status === 'Pago',
        'fa-circle-xmark': status === 'Cancelado',
        'fa-hand-holding-dollar': status === 'Reembolsado',
        '': status === 'Sem status',
      };
    },
    userSession() {
      return this.$store.state.userSession;
  },
  },
 
  methods: {
    async handleRefund() {
      try {
        this.$set(this, 'loading', true)
        const res = await this.$appointmentsService.refund({ password: this.password, appointment_id: this.item.id })
        this.$eventBus.$emit('showToast', { title: 'Reembolso feito', msg: `Reembolto feito com sucesso` })
        this.refundModal.toggle()
        this.$emit('refundConcluded')
      } catch (e) {
        this.errorMsg = e.message
        console.log(e)
      } finally {
        this.$set(this, 'loading', false)
      }
    },
    async handleCancelRecurrency() {
      const confirmCancel = confirm("Tem certeza que deseja cancelar esta recorrência? Somente consultas futuras que NÃO foram pagas serão canceladas.");
      if (!confirmCancel) return;

      try {
        this.errorMessage = ''
        this.submitting = true

        console.log('cancel recurrency id #' + this.item.recurrency?.id)

        const reason_for_cancellation = "Recorrência cancelada no sistema administrativo por " +  this.userSession?.name.toUpperCase();

        console.log(reason_for_cancellation)

        
        await this.$appointmentsService.cancelRecurrency(this.item.recurrency?.id)
       
        this.$eventBus.$emit("showToast", {
          title: "Recorrência cancelada com sucesso.",
          msg: `Sessões futuras que não havia sido pagas foram canceladas.`,
        });
        
        window.location.reload();
      } catch (e) {
        this.errorMessage = e.message
        console.log(e);
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>
